<!--
 * @Author: your name
 * @Date: 2021-08-06 11:23:12
 * @LastEditTime: 2021-08-10 19:18:16
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /anjiboe-frontend/src/views/monitorcenter/VedeoSurveillanceDetail.vue
-->
<template>
  <div :bordered="false" class="border">
    <div class="header">
      <h1>视频监控 > 异常学校</h1>
      <a-button type="primary" @click="goback">返回</a-button>
    </div>
    <div class="table-page-search-wrapper">
      <a-table
        bordered
        :columns="columns"
        :data-source="data"
        :rowKey="(record) => record.channelId"
        :pagination="pagination"
        @change="handleTableChange"
      >
      </a-table>
    </div>
  </div>
  <!-- <div>
    <a-card :title="title" class="left">
      <a-button
        :style="{ marginLeft: '28px' }"
        type="primary"
        ghost
        @click="goback"
        slot="extra"
        >返回</a-button
      >
      <div class="table-page-search-wrapper">
        <a-table
           bordered
          :columns="columns"
          :data-source="data"
          :rowKey="(record) => record.channelId"
          :pagination="pagination"
          @change="handleTableChange"
        >
        </a-table>
      </div>
    </a-card>
  </div>  -->
</template>

<script>
export default {
  name: "VedeoSurveillanceDetail",
  components: {},
  data() {
    return {
      data: [],
      columns: [
        {
          title: "学校",
          dataIndex: "schoolName",
          key: "schoolName",
          align: "center",
        },
        {
          title: "设备总数",
          dataIndex: "total",
          key: "total",
          align: "center",
        },
        {
          title: "离线设备数",
          dataIndex: "off",
          key: "off",
          align: "center",
        },
      ],
      pagination: {
        current: 1,
        total: 0,
        pageSize: 10, //每页中显示10条数据
        showSizeChanger: true,
        pageSizeOptions: ["10", "20", "50", "100"], //每页中显示的数据
        showTotal: (total) => `共有 ${total} 条数据`, //分页中显示总的数据
      },
      title: "异常学校",
    };
  },
  created() {
    this.loadList();
  },

  computed: {},
  methods: {
    loadList: async function () {
      let res = await this.$store.dispatch("deviceHeadDetail");
      if (res.code == 200) {
        this.data = res.result;
        this.pagination.total = res.result.length;
      }
    },
    //分页
    handleTableChange(pagination) {
      this.pagination.current = pagination.current;
      this.pagination.pageSize = pagination.pageSize;
      //   this.queryParam.page = pagination.current;
      //   this.queryParam.pageSize = pagination.pageSize;
      //   this.loadList({});
    },

    goback() {
      this.$router.go(-1); //返回上一层
    },
  },
};
</script>
<style lang="less" scoped>
.border {
  width: 100%;
  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
    margin-bottom: 20px;
    h1 {
      width: 300px;
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #000000;
      line-height: 20px;
    }
  }
}
</style>